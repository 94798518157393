import React from 'react';
const Home = () => {
    return (
        <div>
                 <div id="index">                                          
            <div className="container main">
                <div className="row home">
                    <div id = "index_left" className="col-md-6 left">
                        <img className="img-responsive img-rabbit" src="assets/images/home.jpg" />
                    </div>
                    <div id = "index_right" className="col-md-6 text-center right">
                        <div className="logo">
                            <img className="puru" src="assets/images/namaste.png" />
                            <h4>Purnima Gurung, Software Developer</h4>
                        </div>
                        <p className="home-description">
                            Hi, I am Purnima Gurung, Software developer from Germany. I am passionate about design, development and interaction.
                             I really love what I do.
                        </p>
                        <div className="btn-group-vertical custom_btn animated slideinright">
                            <div id="about" className="btn btn-rabbit"><a href="https://drive.google.com/file/d/1aNB7oH4_Oc-Nl-Vkpb32CUcWtwj9bOxq/view?usp=sharing">Resume</a></div>
                            <div id="work" className="btn btn-rabbit">Work</div>
                            <div id="contact" className="btn btn-rabbit">Contact</div>
                        </div>      
                        <div className="social">
                            <a href="https://www.linkedin.com/in/purnimagurung/"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                            <a href="https://github.com/purnimagurung"><i className="fa fa-github" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>                                                     
        
        <div id="work_scroll" className="pages">                                  /
            <div className="container main">
                <div className="row">
                    <div className="col-md-6" id="work_left">
                        <div id="owl-demo" className="owl-carousel owl-theme">
                            <div className="item"><img className="img-responsive img-rabbit" src="assets/images/work.jpg" /></div>
                        </div>
                    </div>

                    <div className="col-md-6" id="work_right">
                        <a href="#index" className="btn btn-rabbit back"> <i className="fa fa-angle-left" aria-hidden="true"></i> Back to Home </a>
                        <div id="watermark">
                            <h2 className="page-title" text-center>Experience</h2>
                            <div className="marker">w</div>
                        </div>
                        <br />
                            <br />
                        <div class="box">
                            <div className="job clearfix">
                                <div className="col-xs-3">
                                    <div className="where">GEA GROUP GMBH (Dusseldorf, Germany)</div>
                                </div>
                                <div className="col-xs-9">
                                    <div className="profession">Software Engineer (Working student) : August 2020 - Present</div>
                                    <div className="description">
                                        Yii Framework,  HTML5, CSS3, Bootstrap, Azure DevOps, Git
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className="job clearfix">
                                <div className="col-xs-3">
                                    <div className="where">Kul Techno Lab and Research Center Pvt. Ltd (Kathmandu, Nepal)</div>
                                </div>
                                <div className="col-xs-9">
                                    <div className="profession">Web Developer: Feb 2016 - Dec 2018</div>
                                    <div className="description">
                                       Full-stack Web Developer Developing and designing software, planning and documenting, architecture, deployment
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>                                                                


        <div id="contact_scroll" className="pages">                            
            <div className="container main">
                <div className="row">
                    <div className="col-md-6 left" id="contact_left">
                        <img className="img-responsive img-rabbit" src="assets/images/contact.png" />
                    </div>

                    <div className="col-md-6 right" id="contact_right">
                        <a href="#index" className="btn btn-rabbit back"> <i className="fa fa-angle-left" aria-hidden="true"></i> Back to Home </a>
                        <div id="watermark">
                            <h2 className="page-title" text-center>Contact</h2>
                            <div className="marker">c</div>
                        </div>
                        <p className='subtitle'>I'm currently living in Essen, Germany. You can contact with me through the following line.
                        </p>

                        <div className="contact-info">
                            <div className="col-md-12">
                            <div className="col-md-4 address">
                                <ul className="list-unstyled">
                                <li className="txt1"> <i class="fa fa-map-marker p-right" aria-hidden="true"></i> Address</li>
                                <li className="txt2">Essen, Germany </li>
                                </ul>
                            </div>
                            <div className="col-md-4 phone">
                                <ul className="list-unstyled">
                                <li className="txt1"> <i class="fa fa-phone p-right" aria-hidden="true"></i> Skype ID</li>
                                <li className="txt2"> <a href="#">anzu.gurung1 </a></li>
                                </ul>
                            </div>
                            <div className="col-md-4 email">
                                <ul className="list-unstyled">
                                <li className="txt1"> <i class="fa fa-envelope-o p-right" aria-hidden="true"></i> Email</li>
                                <li className="txt2"><a href="mailto:grg.puru@gmail.com.com"> grg.puru@gmail.com</a> </li>
                            </ul></div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>          
        </div>
    )
}

export default Home
